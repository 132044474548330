import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturesSection from "../components/pages/AccountantsAndBookKeepers/FeaturesSection"
import { featuresSection } from "../Content/AccountantsAndBookkeepers"

const Features = () => (
  <Layout>
    <Seo
      title="Features"
      description={`Explore the powerful features of Synkli that streamline financial management. From automated bookkeeping to real-time insights, discover tools designed to simplify your business operations.`}
    />

    <FeaturesSection
      title={featuresSection.title}
      features={featuresSection.features}
    />
  </Layout>
)

export default Features
